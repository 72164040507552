@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./assets/scss2/custom.scss";

// //Import Light mode
@import "./assets/scss2/style.scss";

#topnav {
    background-color: rgba(255,255,255,0.5);
}
#topnav.nav-sticky{
    background-color:whitesmoke;
}
#topnav .navigation-menu {
    @media (min-width: 992px){
        display: flex;
        justify-content: flex-end;
    }
}
#topnav .social-facebook > a,#topnav .social-instagram > a,#topnav .social-linkedin > a {
    color:gray;
}
#topnav .btn.btn-sm, #topnav .btn-group-sm > .btn {
    padding:10px;
}
.bg-subpage {
    padding:2px;
    background-color: white !important;
    height: 139px;
}
.sticky-bar {
    background-color: white;
}
.section.subpage-master {
    padding-top: 0;
}
.btn {
    font-size: 0.9em;
    padding:8px 14px;
}
.section.subpage-master .accounts.active {
    background-color: #659fc3 !important;
}

.shop-title-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 58px;
    background-color: white;
    z-index: 1;
    margin-bottom: 0.5em;

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .breadcrumb {
        letter-spacing: 0.5px;
        padding: 4px 12px;
        display: inline-block;
        margin: 4px 0px;
        line-height: 25px;
        display: inline-block;
    }

    .shop-info {
        display: inline-block;
        
        li {
            font-size:small;
        }
    }
    .shop-summary {
        display: inline-block;
        text-align: right;
        width: auto;
        margin-right: 1em;

        .cart-count {
            color:gray;
            font-size: 0.7rem;
            margin-right: 0.6rem;
        }
        
        .shop-rating{
            display:inline;
        }
    }
}

.widget-title.shop {
    font-size:1.25rem !important;
    color:maroon;
}

.section.subpage-master.shop {
    margin-top:8rem !important;
}

.badge {
    padding:6px 8px !important;
}

.shop {
    .sticky-bar {
        top: 100px;
    }

    .sidebar .widget .widget-search input[type="text"], .sidebar .widget .searchform input[type="text"] {
        height: 36px;
        padding: 3px 6px;
    }

    .sidebar .widget .widget-search .searchform:after {
        top:15px;
        right:5px;
        line-height:6px;
    }
}

.shop-categories {
    overflow: auto;
    max-height: 72vh;
    line-height: 1.2rem;
    
    li {
        padding-bottom: 7px;

        &:last-child {
            padding-bottom: 0;
        }

        a, span {
            font-size: 1rem;
        }

        a {
            color: $dark;
            transition: all 0.5s ease;
            font-size:0.85rem;

            &:hover {
                color: $primary;
            }
        }

        .badge {
            font-size: 0.7rem !important;
        }

        ul {
            margin-top: 0.2rem;
            line-height: 1rem;
            list-style: none;
            padding-left:1rem;
        }

    }
}

.quantity-block {
    border-top: 1px solid aqua;
    border-bottom: 1px solid aqua;
    border-radius: 20px;
}

.quantity {
    text-align: center;
    font-family: monospace;
    padding-top: 3px;
}

.shop-list {

    .content {

        .product-name {
            font-size: 0.9rem;
        }

        .price {
            font-size: 1.2rem;
            font-weight: 600;
            color: darkmagenta;
        }

    }

    &.added {
        border: 1px solid #c4ced4 !important;

        .content {
            background-color: #e6f3fa;
        }
    }
}

.hand-pointer {
    cursor: pointer;
}

.product {
    .img-showcase-container {
        .img-fluid {
            min-width: 58%;
        }
    }

    .img-preview {
        max-width: 5em;
        max-height: 5em;

        &.active {
            opacity: 0.5;
        }
    }

    .product-panel {

        .title {
            font-size: 1.4em !important;
        }

        .price-panel {
        }

        .price {
            font-size: 2em !important;
            font-family: monospace;
        }

        .store {
            background-color: #ffeef8;
            border: 1px solid #ececec;
        }
    }

    li.product-comment {
        border-left: 2px solid #b8cad4;
        padding-left: 1em;
    }

}

.delivery-checkout {
    & .address-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    & .address-item {
        position: relative;
        border-radius: 8px;
        border: solid 1px #e5e5e5;
        background-color: #fcfcfc;
        padding: 0.4rem 0.6rem 0.6rem 1rem;
        height: 150px;
        margin-bottom: 0.5rem;
        margin-right: 10px;
        cursor: pointer;

        & h5 {
            line-height: 1.5;
            letter-spacing: -0.032rem;
            display: -moz-box;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-stretch: normal;
            font-style: normal;
            color: gray;
            margin: 0;
        }

        & p {
            font-size: 0.9em;
            display: -webkit-box;
            line-height: 1.8rem;
            color: #646464;
            letter-spacing: -0.024rem;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            margin-bottom: 0;
        }

        &:hover .top-right-button {
            display: inherit;
        }

        &.selected {
            border: 3px solid #7a90fa;
            -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);

            & h5 {
                color:black;
            }
        }

        &.selectedinvoice {
            border: 3px solid #7ad0fa;
            -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);

            & h5 {
                color:black;
            }
        }

        & .address-detail {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            height: 100%;

            & .city-detail {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 1.8rem;
                color: #646464;
                letter-spacing: -0.024rem;
                font-size: 0.85rem;
            }
        }

        & .top-right-button {
            position: absolute;
            top: 10px;
            right: 12px;
            display: none;
        }
    }
}


//Import Dark RTL mode
// @import './assets/scss2/style-dark-rtl.scss';

//Import RTL mode
// @import './assets/scss2/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss2/style-dark.scss';
